import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "data-table"
    }}>{`Data table`}</h2>
    <ComponentCode name="Data table" component="data-table" variation="data-table" hasReactVersion="datatable--default" hasVueVersion="datatable--default" hasAngularVersion="?path=/story/table--default" codepen="jRWbZZ" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "expandable-data-table"
    }}>{`Expandable data table`}</h2>
    <ComponentCode name="Expandable data table" component="data-table" variation="data-table--expandable" hasReactVersion="datatable--with-expansion" hasAngularVersion="?path=/story/table--with-expansion" codepen="wZMKme" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "data-table-with-pagination"
    }}>{`Data table with pagination`}</h2>
    <ComponentCode name="Data table with pagination" component="data-table" variation="data-table--with-pager" hasAngularVersion="?path=/story/table--with-pagination" codepen="MRKaBq" mdxType="ComponentCode"></ComponentCode>
    {
      /*
      ## Small data table
      <ComponentCode
         name="Small data table"
         component="data-table"
         variation="data-table--small"
         hasAngularVersion="?path=/story/table--default&knob-size=sm&knob-showSelectionColumn=true&knob-striped=true&knob-sortable=true&knob-Data grid keyboard interactions=
         >
      </ComponentCode>
      ## Toolbar
      <ComponentCode
         name="Toolbar"
         component="toolbar"
         variation="toolbar"
         hasVueVersion="toolbar--default"
         hasAngularVersion="?path=/story/table--with-toolbar"
         >
      </ComponentCode>
      */
    }
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="data-table" mdxType="ComponentDocs" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      